const shapes = {
box: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- box -->
<g id="node1" class="node">
<title>box</title>
<polygon fill="lightgrey" stroke="black" points="54,-36 0,-36 0,0 54,0 54,-36"/>
</g>
</g>
</svg>
`,
polygon: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- polygon -->
<g id="node1" class="node">
<title>polygon</title>
<polygon fill="lightgrey" stroke="black" points="54,-36 0,-36 0,0 54,0 54,-36"/>
</g>
</g>
</svg>
`,
ellipse: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- ellipse -->
<g id="node1" class="node">
<title>ellipse</title>
<ellipse fill="lightgrey" stroke="black" cx="27" cy="-18" rx="27" ry="18"/>
</g>
</g>
</svg>
`,
oval: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- oval -->
<g id="node1" class="node">
<title>oval</title>
<ellipse fill="lightgrey" stroke="black" cx="27" cy="-18" rx="27" ry="18"/>
</g>
</g>
</svg>
`,
circle: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="44pt" height="44pt"
 viewBox="0.00 0.00 44.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 40,-40 40,4 -4,4"/>
<!-- circle -->
<g id="node1" class="node">
<title>circle</title>
<ellipse fill="lightgrey" stroke="black" cx="18" cy="-18" rx="18" ry="18"/>
</g>
</g>
</svg>
`,
point: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="12pt" height="12pt"
 viewBox="0.00 0.00 11.60 11.60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 7.6)">
<polygon fill="white" stroke="none" points="-4,4 -4,-7.6 7.6,-7.6 7.6,4 -4,4"/>
<!-- point -->
<g id="node1" class="node">
<title>point</title>
<ellipse fill="black" stroke="black" cx="1.8" cy="-1.8" rx="1.8" ry="1.8"/>
</g>
</g>
</svg>
`,
egg: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="65pt" height="44pt"
 viewBox="0.00 0.00 65.49 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 61.49,-40 61.49,4 -4,4"/>
<!-- egg -->
<g id="node1" class="node">
<title>egg</title>
<polygon fill="lightgrey" stroke="black" points="31.4,-0.05 33.16,-0.15 34.91,-0.3 36.62,-0.49 38.31,-0.74 39.96,-1.03 41.56,-1.36 43.11,-1.75 44.6,-2.18 46.02,-2.65 47.38,-3.16 48.67,-3.71 49.88,-4.31 51.01,-4.94 52.05,-5.61 53.01,-6.31 53.87,-7.04 54.65,-7.8 55.33,-8.59 55.92,-9.41 56.41,-10.25 56.81,-11.11 57.12,-11.99 57.33,-12.89 57.45,-13.8 57.49,-14.72 57.43,-15.65 57.29,-16.59 57.07,-17.53 56.77,-18.47 56.4,-19.41 55.95,-20.35 55.43,-21.28 54.85,-22.2 54.21,-23.11 53.51,-24.01 52.76,-24.89 51.96,-25.75 51.11,-26.59 50.23,-27.41 49.3,-28.2 48.34,-28.96 47.35,-29.69 46.33,-30.39 45.29,-31.06 44.22,-31.69 43.14,-32.29 42.04,-32.84 40.92,-33.35 39.79,-33.82 38.65,-34.25 37.51,-34.64 36.35,-34.97 35.19,-35.26 34.02,-35.51 32.85,-35.7 31.68,-35.85 30.51,-35.95 29.33,-36 28.16,-36 26.98,-35.95 25.81,-35.85 24.63,-35.7 23.46,-35.51 22.3,-35.26 21.14,-34.97 19.98,-34.64 18.83,-34.25 17.7,-33.82 16.57,-33.35 15.45,-32.84 14.35,-32.29 13.27,-31.69 12.2,-31.06 11.16,-30.39 10.14,-29.69 9.15,-28.96 8.19,-28.2 7.26,-27.41 6.37,-26.59 5.53,-25.75 4.73,-24.89 3.97,-24.01 3.28,-23.11 2.63,-22.2 2.05,-21.28 1.54,-20.35 1.09,-19.41 0.71,-18.47 0.41,-17.53 0.19,-16.59 0.05,-15.65 0,-14.72 0.03,-13.8 0.16,-12.89 0.37,-11.99 0.67,-11.11 1.07,-10.25 1.57,-9.41 2.16,-8.59 2.84,-7.8 3.61,-7.04 4.48,-6.31 5.44,-5.61 6.48,-4.94 7.61,-4.31 8.82,-3.71 10.1,-3.16 11.46,-2.65 12.89,-2.18 14.38,-1.75 15.93,-1.36 17.53,-1.03 19.18,-0.74 20.86,-0.49 22.58,-0.3 24.32,-0.15 26.08,-0.05 27.86,0 29.63,0 31.4,-0.05"/>
</g>
</g>
</svg>
`,
triangle: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- triangle -->
<g id="node1" class="node">
<title>triangle</title>
<polygon fill="lightgrey" stroke="black" points="27,-36 0,-9 54,-9 27,-36"/>
</g>
</g>
</svg>
`,
none: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- none -->
<g id="node1" class="node">
<title>none</title>
<polygon fill="lightgrey" stroke="none" points="54,-36 0,-36 0,0 54,0 54,-36"/>
</g>
</g>
</svg>
`,
plaintext: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- plaintext -->
<g id="node1" class="node">
<title>plaintext</title>
<polygon fill="lightgrey" stroke="none" points="54,-36 0,-36 0,0 54,0 54,-36"/>
</g>
</g>
</svg>
`,
plain: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="8pt" height="8pt"
 viewBox="0.00 0.00 8.00 8.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 4)">
<polygon fill="white" stroke="none" points="-4,4 -4,-4 4,-4 4,4 -4,4"/>
<!-- plain -->
<g id="node1" class="node">
<title>plain</title>
<polygon fill="lightgrey" stroke="none" points="-nan,nan -nan,nan -nan,nan -nan,nan -nan,nan"/>
</g>
</g>
</svg>
`,
diamond: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- diamond -->
<g id="node1" class="node">
<title>diamond</title>
<polygon fill="lightgrey" stroke="black" points="27,-36 0,-18 27,0 54,-18 27,-36"/>
</g>
</g>
</svg>
`,
trapezium: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- trapezium -->
<g id="node1" class="node">
<title>trapezium</title>
<polygon fill="lightgrey" stroke="black" points="42.77,-36 11.23,-36 0,0 54,0 42.77,-36"/>
</g>
</g>
</svg>
`,
parallelogram: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="64pt" height="44pt"
 viewBox="0.00 0.00 63.98 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 59.98,-40 59.98,4 -4,4"/>
<!-- parallelogram -->
<g id="node1" class="node">
<title>parallelogram</title>
<polygon fill="lightgrey" stroke="black" points="55.98,-36 11.46,-36 0,0 44.53,0 55.98,-36"/>
</g>
</g>
</svg>
`,
house: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- house -->
<g id="node1" class="node">
<title>house</title>
<polygon fill="lightgrey" stroke="black" points="54,-23.56 27,-36 0,-23.56 0.03,-3.44 53.97,-3.44 54,-23.56"/>
</g>
</g>
</svg>
`,
pentagon: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- pentagon -->
<g id="node1" class="node">
<title>pentagon</title>
<polygon fill="lightgrey" stroke="black" points="54,-23.56 27,-36 0,-23.56 10.31,-3.44 43.69,-3.44 54,-23.56"/>
</g>
</g>
</svg>
`,
hexagon: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- hexagon -->
<g id="node1" class="node">
<title>hexagon</title>
<polygon fill="lightgrey" stroke="black" points="54,-18 40.5,-36 13.5,-36 0,-18 13.5,0 40.5,0 54,-18"/>
</g>
</g>
</svg>
`,
septagon: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- septagon -->
<g id="node1" class="node">
<title>septagon</title>
<polygon fill="lightgrey" stroke="black" points="54,-13.99 48.65,-29.22 27,-36 5.35,-29.22 0,-13.99 14.98,-1.78 39.02,-1.78 54,-13.99"/>
</g>
</g>
</svg>
`,
octagon: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- octagon -->
<g id="node1" class="node">
<title>octagon</title>
<polygon fill="lightgrey" stroke="black" points="54,-10.54 54,-25.46 38.18,-36 15.82,-36 0,-25.46 0,-10.54 15.82,0 38.18,0 54,-10.54"/>
</g>
</g>
</svg>
`,
note: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- note -->
<g id="node1" class="node">
<title>note</title>
<polygon fill="lightgrey" stroke="black" points="48,-36 0,-36 0,0 54,0 54,-30 48,-36"/>
<polyline fill="none" stroke="black" points="48,-36 48,-30"/>
<polyline fill="none" stroke="black" points="54,-30 48,-30"/>
</g>
</g>
</svg>
`,
tab: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- tab -->
<g id="node1" class="node">
<title>tab</title>
<polygon fill="lightgrey" stroke="black" points="54,-36 12,-36 12,-40 0,-40 0,0 54,0 54,-36"/>
<polyline fill="none" stroke="black" points="0,-36 12,-36"/>
</g>
</g>
</svg>
`,
folder: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- folder -->
<g id="node1" class="node">
<title>folder</title>
<polygon fill="lightgrey" stroke="black" points="54,-36 51,-40 30,-40 27,-36 0,-36 0,0 54,0 54,-36"/>
</g>
</g>
</svg>
`,
box3d: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- box3d -->
<g id="node1" class="node">
<title>box3d</title>
<polygon fill="lightgrey" stroke="black" points="54,-36 4,-36 0,-32 0,0 50,0 54,-4 54,-36"/>
<polyline fill="none" stroke="black" points="50,-32 0,-32"/>
<polyline fill="none" stroke="black" points="50,-32 50,0"/>
<polyline fill="none" stroke="black" points="50,-32 54,-36"/>
</g>
</g>
</svg>
`,
component: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- component -->
<g id="node1" class="node">
<title>component</title>
<polygon fill="lightgrey" stroke="black" points="54,-36 0,-36 0,-32 -4,-32 -4,-28 0,-28 0,-8 -4,-8 -4,-4 0,-4 0,0 54,0 54,-36"/>
<polyline fill="none" stroke="black" points="0,-32 4,-32 4,-28 0,-28"/>
<polyline fill="none" stroke="black" points="0,-8 4,-8 4,-4 0,-4"/>
</g>
</g>
</svg>
`,
cylinder: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- cylinder -->
<g id="node1" class="node">
<title>cylinder</title>
<path fill="lightgrey" stroke="black" d="M54,-32.73C54,-34.53 41.9,-36 27,-36 12.1,-36 0,-34.53 0,-32.73 0,-32.73 0,-3.27 0,-3.27 0,-1.47 12.1,0 27,0 41.9,0 54,-1.47 54,-3.27 54,-3.27 54,-32.73 54,-32.73"/>
<path fill="none" stroke="black" d="M54,-32.73C54,-30.92 41.9,-29.45 27,-29.45 12.1,-29.45 0,-30.92 0,-32.73"/>
</g>
</g>
</svg>
`,
rect: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- rect -->
<g id="node1" class="node">
<title>rect</title>
<polygon fill="lightgrey" stroke="black" points="54,-36 0,-36 0,0 54,0 54,-36"/>
</g>
</g>
</svg>
`,
rectangle: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- rectangle -->
<g id="node1" class="node">
<title>rectangle</title>
<polygon fill="lightgrey" stroke="black" points="54,-36 0,-36 0,0 54,0 54,-36"/>
</g>
</g>
</svg>
`,
square: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="44pt" height="44pt"
 viewBox="0.00 0.00 44.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 40,-40 40,4 -4,4"/>
<!-- square -->
<g id="node1" class="node">
<title>square</title>
<polygon fill="lightgrey" stroke="black" points="36,-36 0,-36 0,0 36,0 36,-36"/>
</g>
</g>
</svg>
`,
doublecircle: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="52pt" height="52pt"
 viewBox="0.00 0.00 52.00 52.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 48)">
<polygon fill="white" stroke="none" points="-4,4 -4,-48 48,-48 48,4 -4,4"/>
<!-- doublecircle -->
<g id="node1" class="node">
<title>doublecircle</title>
<ellipse fill="lightgrey" stroke="black" cx="22" cy="-22" rx="18" ry="18"/>
<ellipse fill="none" stroke="black" cx="22" cy="-22" rx="22" ry="22"/>
</g>
</g>
</svg>
`,
doubleoctagon: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="70pt" height="52pt"
 viewBox="0.00 0.00 70.00 52.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 48)">
<polygon fill="white" stroke="none" points="-4,4 -4,-48 66,-48 66,4 -4,4"/>
<!-- doubleoctagon -->
<g id="node1" class="node">
<title>doubleoctagon</title>
<polygon fill="lightgrey" stroke="black" points="58,-14.54 58,-29.46 42.18,-40 19.82,-40 4,-29.46 4,-14.54 19.82,-4 42.18,-4 58,-14.54"/>
<polygon fill="none" stroke="black" points="62,-12.4 62,-31.6 43.39,-44 18.61,-44 0,-31.6 0,-12.4 18.61,0 43.39,0 62,-12.4"/>
</g>
</g>
</svg>
`,
tripleoctagon: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="78pt" height="60pt"
 viewBox="0.00 0.00 78.00 60.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 56)">
<polygon fill="white" stroke="none" points="-4,4 -4,-56 74,-56 74,4 -4,4"/>
<!-- tripleoctagon -->
<g id="node1" class="node">
<title>tripleoctagon</title>
<polygon fill="lightgrey" stroke="black" points="62,-18.54 62,-33.46 46.18,-44 23.82,-44 8,-33.46 8,-18.54 23.82,-8 46.18,-8 62,-18.54"/>
<polygon fill="none" stroke="black" points="66,-16.4 66,-35.6 47.39,-48 22.61,-48 4,-35.6 4,-16.4 22.61,-4 47.39,-4 66,-16.4"/>
<polygon fill="none" stroke="black" points="70,-14.26 70,-37.74 48.61,-52 21.39,-52 0,-37.74 0,-14.26 21.39,0 48.61,0 70,-14.26"/>
</g>
</g>
</svg>
`,
invtriangle: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- invtriangle -->
<g id="node1" class="node">
<title>invtriangle</title>
<polygon fill="lightgrey" stroke="black" points="27,0 54,-27 0,-27 27,0"/>
</g>
</g>
</svg>
`,
invtrapezium: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- invtrapezium -->
<g id="node1" class="node">
<title>invtrapezium</title>
<polygon fill="lightgrey" stroke="black" points="11.23,0 42.77,0 54,-36 0,-36 11.23,0"/>
</g>
</g>
</svg>
`,
invhouse: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- invhouse -->
<g id="node1" class="node">
<title>invhouse</title>
<polygon fill="lightgrey" stroke="black" points="0,-12.44 27,0 54,-12.44 53.97,-32.56 0.03,-32.56 0,-12.44"/>
</g>
</g>
</svg>
`,
underline: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- underline -->
<g id="node1" class="node">
<title>underline</title>
<polygon fill="lightgrey" stroke="none" points="54,-36 0,-36 0,0 54,0 54,-36"/>
<polyline fill="none" stroke="black" points="0,0 54,0"/>
</g>
</g>
</svg>
`,
Mdiamond: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- Mdiamond -->
<g id="node1" class="node">
<title>Mdiamond</title>
<polygon fill="lightgrey" stroke="black" points="27,-36 0,-18 27,0 54,-18 27,-36"/>
<polyline fill="none" stroke="black" points="9,-24 9,-12"/>
<polyline fill="none" stroke="black" points="18,-6 36,-6"/>
<polyline fill="none" stroke="black" points="45,-12 45,-24"/>
<polyline fill="none" stroke="black" points="36,-30 18,-30"/>
</g>
</g>
</svg>
`,
Msquare: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="44pt" height="44pt"
 viewBox="0.00 0.00 44.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 40,-40 40,4 -4,4"/>
<!-- Msquare -->
<g id="node1" class="node">
<title>Msquare</title>
<polygon fill="lightgrey" stroke="black" points="36,-36 0,-36 0,0 36,0 36,-36"/>
<polyline fill="none" stroke="black" points="12,-36 0,-24"/>
<polyline fill="none" stroke="black" points="0,-12 12,0"/>
<polyline fill="none" stroke="black" points="24,0 36,-12"/>
<polyline fill="none" stroke="black" points="36,-24 24,-36"/>
</g>
</g>
</svg>
`,
Mcircle: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="44pt" height="44pt"
 viewBox="0.00 0.00 44.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 40,-40 40,4 -4,4"/>
<!-- Mcircle -->
<g id="node1" class="node">
<title>Mcircle</title>
<ellipse fill="lightgrey" stroke="black" cx="18" cy="-18" rx="18" ry="18"/>
<polyline fill="none" stroke="black" points="29.91,-31.5 6.09,-31.5"/>
<polyline fill="none" stroke="black" points="29.91,-4.5 6.09,-4.5"/>
</g>
</g>
</svg>
`,
promoter: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- promoter -->
<g id="node1" class="node">
<title>promoter</title>
<polygon fill="lightgrey" stroke="black" points="33.75,-36 13.5,-36 13.5,-18 19.5,-18 19.5,-30 33.75,-30 33.75,-27 45.75,-33 33.75,-39 33.75,-36"/>
<polyline fill="none" stroke="black" points="0,-18 54,-18"/>
</g>
</g>
</svg>
`,
cds: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- cds -->
<g id="node1" class="node">
<title>cds</title>
<polygon fill="lightgrey" stroke="black" points="42,-30 0,-30 0,-6 42,-6 54,-18 42,-30"/>
</g>
</g>
</svg>
`,
terminator: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- terminator -->
<g id="node1" class="node">
<title>terminator</title>
<polygon fill="lightgrey" stroke="black" points="30,-18 30,-24 36,-24 36,-30 18,-30 18,-24 24,-24 24,-18 30,-18"/>
<polyline fill="none" stroke="black" points="0,-18 54,-18"/>
</g>
</g>
</svg>
`,
utr: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- utr -->
<g id="node1" class="node">
<title>utr</title>
<polygon fill="lightgrey" stroke="black" points="36,-18 36,-21 30,-27 24,-27 18,-21 18,-18 36,-18"/>
<polyline fill="none" stroke="black" points="0,-18 54,-18"/>
</g>
</g>
</svg>
`,
insulator: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- insulator -->
<g id="node1" class="node">
<title>insulator</title>
<polygon fill="lightgrey" stroke="black" points="33,-24 33,-12 21,-12 21,-24 33,-24"/>
<polyline fill="none" stroke="black" points="36,-27 36,-9 18,-9 18,-27 36,-27"/>
<polyline fill="none" stroke="black" points="36,-18 54,-18"/>
<polyline fill="none" stroke="black" points="0,-18 18,-18"/>
</g>
</g>
</svg>
`,
ribosite: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- ribosite -->
<g id="node1" class="node">
<title>ribosite</title>
<polygon fill="lightgrey" stroke="black" points="30,-24 30,-25.5 28.5,-27 30,-28.5 30,-30 28.5,-30 27,-28.5 25.5,-30 24,-30 24,-28.5 25.5,-27 24,-25.5 24,-24 25.5,-24 27,-25.5 28.5,-24 30,-24"/>
<polyline fill="none" stroke="black" points="27,-18 27,-19.5"/>
<polyline fill="none" stroke="black" points="27,-21 27,-22.5"/>
<polyline fill="none" stroke="black" points="0,-18 54,-18"/>
</g>
</g>
</svg>
`,
rnastab: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- rnastab -->
<g id="node1" class="node">
<title>rnastab</title>
<polygon fill="lightgrey" stroke="black" points="28.5,-24 30,-25.5 30,-28.5 28.5,-30 25.5,-30 24,-28.5 24,-25.5 25.5,-24 28.5,-24"/>
<polyline fill="none" stroke="black" points="27,-18 27,-19.5"/>
<polyline fill="none" stroke="black" points="27,-21 27,-22.5"/>
<polyline fill="none" stroke="black" points="0,-18 54,-18"/>
</g>
</g>
</svg>
`,
proteasesite: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- proteasesite -->
<g id="node1" class="node">
<title>proteasesite</title>
<polygon fill="lightgrey" stroke="black" points="30,-24 30,-25.5 28.5,-27 30,-28.5 30,-30 28.5,-30 27,-28.5 25.5,-30 24,-30 24,-28.5 25.5,-27 24,-25.5 24,-24 25.5,-24 27,-25.5 28.5,-24 30,-24"/>
<polyline fill="none" stroke="black" points="27,-25.5 27,-18"/>
<polyline fill="none" stroke="black" points="0,-18 54,-18"/>
</g>
</g>
</svg>
`,
proteinstab: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- proteinstab -->
<g id="node1" class="node">
<title>proteinstab</title>
<polygon fill="lightgrey" stroke="black" points="28.5,-24 30,-25.5 30,-28.5 28.5,-30 25.5,-30 24,-28.5 24,-25.5 25.5,-24 28.5,-24"/>
<polyline fill="none" stroke="black" points="27,-24 27,-18"/>
<polyline fill="none" stroke="black" points="0,-18 54,-18"/>
</g>
</g>
</svg>
`,
primersite: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- primersite -->
<g id="node1" class="node">
<title>primersite</title>
<polygon fill="lightgrey" stroke="black" points="39,-21 27,-33 27,-27 13.5,-27 13.5,-21 39,-21"/>
<polyline fill="none" stroke="black" points="0,-18 54,-18"/>
</g>
</g>
</svg>
`,
restrictionsite: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- restrictionsite -->
<g id="node1" class="node">
<title>restrictionsite</title>
<polygon fill="lightgrey" stroke="black" points="39.75,-21 20.25,-21 20.25,-27 14.25,-27 14.25,-15 33.75,-15 33.75,-9 39.75,-9 39.75,-21"/>
<polyline fill="none" stroke="black" points="0,-18 14.25,-18"/>
<polyline fill="none" stroke="black" points="39.75,-18 54,-18"/>
</g>
</g>
</svg>
`,
fivepoverhang: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- fivepoverhang -->
<g id="node1" class="node">
<title>fivepoverhang</title>
<polygon fill="lightgrey" stroke="black" points="0,-19.5 24,-19.5 24,-25.5 0,-25.5 0,-19.5"/>
<polygon fill="lightgrey" stroke="black" points="12,-10.5 24,-10.5 24,-16.5 12,-16.5 12,-10.5"/>
<polyline fill="none" stroke="black" points="24,-18 54,-18"/>
</g>
</g>
</svg>
`,
threepoverhang: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- threepoverhang -->
<g id="node1" class="node">
<title>threepoverhang</title>
<polygon fill="lightgrey" stroke="black" points="54,-19.5 54,-25.5 30,-25.5 30,-19.5 54,-19.5"/>
<polygon fill="lightgrey" stroke="black" points="42,-10.5 42,-16.5 30,-16.5 30,-10.5 42,-10.5"/>
<polyline fill="none" stroke="black" points="0,-18 30,-18"/>
</g>
</g>
</svg>
`,
noverhang: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- noverhang -->
<g id="node1" class="node">
<title>noverhang</title>
<polygon fill="lightgrey" stroke="black" points="13.5,-19.5 25.5,-19.5 25.5,-25.5 13.5,-25.5 13.5,-19.5"/>
<polygon fill="lightgrey" stroke="black" points="13.5,-10.5 25.5,-10.5 25.5,-16.5 13.5,-16.5 13.5,-10.5"/>
<polygon fill="lightgrey" stroke="black" points="28.5,-10.5 40.5,-10.5 40.5,-16.5 28.5,-16.5 28.5,-10.5"/>
<polygon fill="lightgrey" stroke="black" points="28.5,-19.5 40.5,-19.5 40.5,-25.5 28.5,-25.5 28.5,-19.5"/>
<polyline fill="none" stroke="black" points="40.5,-18 54,-18"/>
<polyline fill="none" stroke="black" points="13.5,-18 0,-18"/>
</g>
</g>
</svg>
`,
assembly: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- assembly -->
<g id="node1" class="node">
<title>assembly</title>
<polygon fill="lightgrey" stroke="black" points="15,-19.5 39,-19.5 39,-25.5 15,-25.5 15,-19.5"/>
<polygon fill="lightgrey" stroke="black" points="15,-10.5 39,-10.5 39,-16.5 15,-16.5 15,-10.5"/>
<polyline fill="none" stroke="black" points="39,-18 54,-18"/>
<polyline fill="none" stroke="black" points="0,-18 15,-18"/>
</g>
</g>
</svg>
`,
signature: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- signature -->
<g id="node1" class="node">
<title>signature</title>
<polygon fill="lightgrey" stroke="black" points="54,-30 0,-30 0,-6 54,-6 54,-30"/>
<polyline fill="none" stroke="black" points="3,-19.5 6,-16.5"/>
<polyline fill="none" stroke="black" points="3,-16.5 6,-19.5"/>
<polyline fill="none" stroke="black" points="3,-9 51,-9"/>
</g>
</g>
</svg>
`,
rpromoter: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- rpromoter -->
<g id="node1" class="node">
<title>rpromoter</title>
<polygon fill="lightgrey" stroke="black" points="36,-30 0,-30 0,0 18,0 18,-6 36,-6 36,0 54,-18 36,-36 36,-30"/>
</g>
</g>
</svg>
`,
larrow: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- larrow -->
<g id="node1" class="node">
<title>larrow</title>
<polygon fill="lightgrey" stroke="black" points="54,-30 18,-30 18,-36 0,-18 18,0 18,-6 54,-6 54,-30"/>
</g>
</g>
</svg>
`,
rarrow: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- rarrow -->
<g id="node1" class="node">
<title>rarrow</title>
<polygon fill="lightgrey" stroke="black" points="36,-30 0,-30 0,-6 36,-6 36,0 54,-18 36,-36 36,-30"/>
</g>
</g>
</svg>
`,
lpromoter: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- lpromoter -->
<g id="node1" class="node">
<title>lpromoter</title>
<polygon fill="lightgrey" stroke="black" points="54,-30 18,-30 18,-36 0,-18 18,0 18,-6 36,-6 36,0 54,0 54,-30"/>
</g>
</g>
</svg>
`,
record: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="45pt"
 viewBox="0.00 0.00 62.00 45.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 41)">
<polygon fill="white" stroke="none" points="-4,4 -4,-41 58,-41 58,4 -4,4"/>
<!-- record -->
<g id="node1" class="node">
<title>record</title>
<polygon fill="lightgrey" stroke="black" points="0,-0.5 0,-36.5 54,-36.5 54,-0.5 0,-0.5"/>
<text text-anchor="middle" x="26.75" y="-14.3" font-family="Times,serif" font-size="14.00"> </text>
</g>
</g>
</svg>
`,
Mrecord: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="45pt"
 viewBox="0.00 0.00 62.00 45.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 41)">
<polygon fill="white" stroke="none" points="-4,4 -4,-41 58,-41 58,4 -4,4"/>
<!-- Mrecord -->
<g id="node1" class="node">
<title>Mrecord</title>
<path fill="lightgrey" stroke="black" d="M12,-0.5C12,-0.5 42,-0.5 42,-0.5 48,-0.5 54,-6.5 54,-12.5 54,-12.5 54,-24.5 54,-24.5 54,-30.5 48,-36.5 42,-36.5 42,-36.5 12,-36.5 12,-36.5 6,-36.5 0,-30.5 0,-24.5 0,-24.5 0,-12.5 0,-12.5 0,-6.5 6,-0.5 12,-0.5"/>
<text text-anchor="middle" x="26.75" y="-14.3" font-family="Times,serif" font-size="14.00"> </text>
</g>
</g>
</svg>
`,
star: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="59pt"
 viewBox="0.00 0.00 62.00 59.36" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 55.36)">
<polygon fill="white" stroke="none" points="-4,4 -4,-55.36 58,-55.36 58,4 -4,4"/>
<!-- star -->
<g id="node1" class="node">
<title>star</title>
<polygon fill="lightgrey" stroke="black" points="54,-31.74 33.37,-31.74 27,-51.36 20.63,-31.74 0,-31.74 16.69,-19.62 10.31,0 27,-12.12 43.69,0 37.31,-19.62 54,-31.74"/>
</g>
</g>
</svg>
`,
'(default)': `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
 "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Generated by graphviz version 11.0.0 (0)
 -->
<!-- Pages: 1 -->
<svg width="62pt" height="44pt"
 viewBox="0.00 0.00 62.00 44.00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="graph0" class="graph" transform="scale(1 1) rotate(0) translate(4 40)">
<polygon fill="white" stroke="none" points="-4,4 -4,-40 58,-40 58,4 -4,4"/>
<!-- (default) -->
<g id="node1" class="node">
<title>(default)</title>
<ellipse fill="white" stroke="black" stroke-dasharray="5,2" cx="27" cy="-18" rx="27" ry="18"/>
</g>
</g>
</svg>
`,
};

export {shapes};
